<template>
  <div>
    <div class="contract-detail-page">
      <van-form  class="submit-data" :label-width="100">
        <van-field v-model="contract.contract_no"     label="申请编号"  readonly  />
        <van-field v-model="contract.company_name"   label="出租公司" readonly  />
        <van-field v-model="contract.type_text"   label="合同类型" readonly  />
        <van-field v-model="contract.purpose"   label="具体用途" readonly  />
        <van-field v-model="contract.use_time"   label="合同有效期" readonly  />
        <van-field v-model="contract.stall_no"   label="场地位置" readonly  />
        <van-field v-model="contract.unit_price"   label="租金单价（元）" readonly  />
        <van-field v-model="contract.rental_area"   label="面积（m²）" readonly  />
        <van-field v-model="contract.site_rent_amount"   label="场地租金（￥）" readonly  />
        <van-field v-model="contract.ensure_amount"   label="保证金（￥）" readonly  />
        <van-field v-model="contract.market_fee_amount"   label="管理费（￥）" readonly  />
        <van-field v-model="contract.collection_cycle_type_text"   label="支付周期" readonly  />
        <van-cell-group class="contract-end" style="margin-top: 10px">
            <van-cell center title="合同总金额" :value="(contract.total_amount)" class="tax-cell"></van-cell>
            <van-cell center title="已支付金额" :value="(contract.pay_total_amount)" class="tax-cell" is-link @click="showFee"></van-cell>
        </van-cell-group>
        <van-cell-group class="contract-end" style="margin-top: 10px" v-if="contract.is_end==2 && is_show == 2 && is_end_pay==1">
          <van-cell center title="应付款时间" :value="(cycle_time)" class="tax-cell"></van-cell>
          <van-cell center title="应付款金额" :value="(cycle_total_amount)" class="tax-cell"></van-cell>
        </van-cell-group>
      </van-form>
    </div>
    <!-- 已支付费用明细 -->
    <van-popup
        class="tax-popup"
        v-model:show="fee_show"
        closeable
        position="bottom"
        :style="{ height: '32%' }">
      <h3 class="tax-pop-title">已支付费用明细</h3>
      <van-cell-group class="tax-edit">
        <van-cell center title="已付保证金" :value="(fee_detail.total_ensure_amount)" class="tax-cell"></van-cell>
        <van-cell center title="已付租金" :value="(fee_detail.total_rental_amount)" class="tax-cell"></van-cell>
        <van-cell center title="已付管理费" :value="(fee_detail.total_market_amount)" class="tax-cell"></van-cell>
      </van-cell-group>
    </van-popup>
  </div>
</template>
<script>

import {reactive, toRefs} from "vue";
import {ContractDetail} from "../../api/contract";
export default {
  setup() {
    const state = reactive({
      fee_show:false,//已支付费用明细弹窗
      contract:{},
      fee_detail:{
        'total_ensure_amount':'0.00',//已付保证金
        'total_rental_amount':'0.00',//已付租金
        'total_market_amount':'0.00',//已付管理费
      },
      is_show:'',
      is_end_pay:'',
      cycle_time:'',
      cycle_total_amount:'0.00',
    });

    const showFee = () => {
      state.fee_show = true;
    };
    return {
      showFee,
      ...toRefs(state),
    };
  },
  created: function () {
    this.detail();
  },
  methods:{
    detail(){
      let _this = this
      let id = _this.$route.query.id;
      if(id !== '0'){
        // 异步更新数据
        ContractDetail({id:id}).then(function (res){
          _this.contract  = res.Data.contract;
          _this.fee_detail.total_ensure_amount  = res.Data.total_ensure_amount;
          _this.fee_detail.total_rental_amount  = res.Data.total_rental_amount;
          _this.fee_detail.total_market_amount  = res.Data.total_market_amount;
          _this.is_show  = res.Data.is_show;
          _this.is_end_pay  = res.Data.is_end_pay;
          _this.cycle_time  = res.Data.cycle_time;
          _this.cycle_total_amount  = res.Data.cycle_total_amount;

        }).catch(function (err){
          console.info(err)
        })
      }
    },

  }
};
</script>


<style lang="less" scoped>
.van-button-goods::after{
  border-bottom: unset;
}
.submit-button-btn{
  background-color:#ff552e;
}
.contract-detail-page{
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: #F6F6F6;
  padding-bottom: 60px;
  :deep(.delete-button) {
    height: 100%;
  }
  :deep(.submit-data .van-field__control){
    text-align: right;
  }
  :deep(.contract-add-button){
    width: 60vw;
    height: 6vh;
    background-color: #ffd9cd;
    border-radius: 4px;
    margin: 2vh auto;
    color: #ff552e;
    border: unset;
  }
  :deep(.contract-desc){
    height: 25px;
    font-size: 13px;
    font-weight: 400;
    color: #666666;
  }
  :global(.tax-edit .van-cell__title){
    text-align: left;
    color: #999999;
    font-size: 13px;
  }
  :global(.tax-edit .van-cell__value){
    color: #333333;
    font-size: 13px;
  }
  :deep(.van-cell__title){
    text-align: left;
    color: #999999;
    font-size: 13px;
  }
  :deep(.van-cell__value){
    color: #333333;
    font-size: 13px;
  }
  :global(.item-edit .van-cell__title){
      text-align: left;
      color: #999999;
      font-size: 13px;
  }
  :global(.item-edit .van-cell__value){
    color: #333333;
    font-size: 13px;
  }
}
.submit-button-group{
  bottom: 0;
  position: fixed;
  display: flex;
  width: 100%;
  height: 8vh;
  background: #FFFFFF;
}
.submit-button-group{
  :deep(button){
    margin: auto 20px;
    height: 35px;
  }
}
.block-title{
  margin: 0 auto;
  padding: 10px 10px 10px;
  color: rgba(69, 90, 100, 0.6);
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}
.goods-icon{
  right: 0;
  margin-left: 0.10667rem;
  color: #969799;
  float: right;
  height: 0.64rem;
  font-size: 0.42667rem;
  line-height: 0.64rem;
}

.contract-info{
  margin-top: 10px;
  margin-bottom: 10px;
}
.contract-end{
  margin-bottom: 10px;
}
.van-field__label{
  color: #999999;
  font-size: 13px;
}
.item-edit .van-field__control{

}
:global(.question_mark){
  width: 4rem;
  height: 3rem;
}
:global(.action_list){
  width: 7rem!important;
  margin-left: 4.6rem!important;
  margin-top: -0.5rem!important;
}
:global(.tax-pop-title){
  display: block;
  text-align: center;
  font-size: 14px;
}
.van-dialog{
  :global(.van-cell__value){
    color: #333333;
  }
  :global(.van-cell__title){
    text-align: left;
  }
}

</style>
